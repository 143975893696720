import React, {createContext,useState} from 'react'

export const UsuarioContext =  createContext({});


export const UsuarioProvider = ({children})=>{
    const [usuario, usuarioSet] = useState({});

    return <UsuarioContext.Provider value={{usuario, usuarioSet}}>
            {children}
            </UsuarioContext.Provider>
}
