// import Fetch from './fetch'

import * as Constants from '../context/constants';

const WS = {


    obtenerCredenciales : (email, password)=>{
        //voy a buscar estos datos..
        //Antes, teng que hashear la password (porque es lo que está en la BD)
        return {
            "nombre":"Andres",
            "id":1
        };
    },
    traer : (urlRoute=null, method="GET", secure="")=>{
        // console.log("jwt recibido:", secure);
        return new Promise( (resolve, reject)=>{
            if(!urlRoute){
                let err = new Error("Debe cargar una ruta de api");
                reject(err);
            }

            let myHeaders = new Headers();
            if(secure!==""){
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", "Bearer " + secure);
            } else {
                myHeaders.append("Content-Type", "text/plain");
            }

            // console.log("my header:", myHeaders);
            let miInit = { 
                method: method,
                headers: myHeaders,
                mode: 'cors',
                cache: 'no-cache' , 
                crossDomain: true
            };
            // console.log("buscando..",Constants.URLAPI + urlRoute)
            fetch(Constants.URLAPI + urlRoute, miInit)
            .then( (response)=>{
                if(!response.ok){
                    let err = new Error("Invalid response code " + response.status);
                    throw err;
                }
                return response.json();
            })
            .then( (json) => {
                
                setTimeout(()=>{
                    resolve(json);                    
                },Constants.DELAYFETCH);

                
            })
            .catch( (err)=>{
                reject(err);
            });
        } );
    },
    enviar : (urlRoute=null, data, mymethod="POST", secure="")=>{
        return new Promise( (resolve, reject)=>{
            if(!urlRoute){
                let err = new Error("Debe cargar una ruta de api");
                reject(err);
            }
            
            let myHeaders = new Headers();
            if(secure!==""){
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", "Bearer " + secure);
            } else {
                myHeaders.append("Content-Type", "application/json");
            }

            let miInit = { 
                method: mymethod,
                headers: myHeaders,
                mode: 'cors',
                cache: 'no-cache' , 
                crossDomain: true,
                body : JSON.stringify(data)
            };
            // console.log("A punto de enviar:",Constants.URLAPI + urlRoute);
            // console.log(miInit);
            
            fetch(Constants.URLAPI + urlRoute, miInit)
            .then( (response)=>{
                if(!response.ok){
                    // console.log("error en el fetch..", response.status)
                    let err = new Error("Invalid response code " + response.status);
                    throw err;
                }
                // console.log(response);
                return response.json();
            })
            .then( (json) => {
                // console.log("esperando 2 segundos para mandar la respuesta del json...", json);
                setTimeout(()=>{
                    resolve(json);                    
                },Constants.DELAYFETCH);                
            })
            .catch( (err)=>{
                // console.log("salió por el catch de -enviar- ", err);
                reject(err);
            });
        } );

    }
}

export default WS;