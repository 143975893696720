/* -- Variables del sistema -- */
// const BASEAPIURL="http://localhost:4000/";

// Trabajar en produccion del hosting
// let BASEAPIURL="http://localhost:8080/api/";
// const APIVERSION="v1/";

//Trabajar en test del hosting
let BASEAPIURL="https://test.usca.org.ar/api/";
const APIVERSION="v1/";


//Trabajar  con el servido local de php
// let BASEAPIURL="http://localhost:3004/";
// const APIVERSION="";

//Trabajar con local json-server:
// const APIVERSION="";
// let BASEAPIURL="http://localhost:3004/";

// let BASEAPIURL="http://localhost:8080/api/";
// let BASEAPIURL="https://test.usca.org.ar/api/";
// let BASEAPIURL="https://www.usca.org.ar/api/";
// if(!process.env.REACT_APP_BASEAPIURL){
//     BASEAPIURL = "http://test.usca.org.ar/api/";
// } else {
//     BASEAPIURL = `${process.env.REACT_APP_BASEAPIURL}`;
// };

// console.log(BASEAPIURL);
export const URLAPI=BASEAPIURL+APIVERSION ;
export const DELAYFETCH = 1000; /* Variable utilizada para retrasar el llamado a la api */
export const DELAYALERTS = 2000; /* Variable utilizada para retrasar el llamado a la api */