import React, {useState} from 'react';
import Textarea from '../base/textarea';
import PropTypes from 'prop-types';

const Mensaje = ( {
        Titulo="Mensaje",
        Id="mensaje",
        TieneErrores=false,
        ErrorMessage = "Debe completar el mensaje",
        PlaceHolder="introduzca su Mensaje", 
        Valor="", 
        ValorSet=()=>console.log("falta agregar la función para asignación en Mensaje.")
    } )=>{

    let [mensaje, mensajeSet] = useState(Valor);

    function actualizarMensaje(nuevoValor){
        mensajeSet(nuevoValor);
        ValorSet(nuevoValor);
    }

    return (
        <div className="w3-container">

            <Textarea 
                Id={Id} 
                Titulo={Titulo} 
                TieneErrores = {TieneErrores}
                ErrorMessage={ErrorMessage}
                PlaceHolder={PlaceHolder} 
                Valor={mensaje} 
                ValorSet={actualizarMensaje}  
            />
        </div>
    );
}

Mensaje.propTypes = {
    Titulo: PropTypes.string.isRequired,
    Id : PropTypes.any.isRequired,
    TieneErrores: PropTypes.bool,
    ErrorMessage: PropTypes.string,
    PlaceHolder : PropTypes.string,
    Valor : PropTypes.string.isRequired,
    ValorSet: PropTypes.func
}

export default Mensaje;