
import React, {useState, useContext, useEffect} from 'react';


import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Biblioteca from './components/complex/biblioteca'
import Nosotros from './components/complex/nosotros';
import Home from './components/complex/home';
import Dashboard from './components/complex/dashboard';
import Contacto from './components/complex/contacto';
import Usuario from './components/complex/usuario';
import Registro from './components/complex/registro';
import Ramas from './components/complex/ramas';
import Rama from './components/complex/rama';
import Cursos from './components/complex/cursos';

import Login from './components/complex/login';
import Header from './components/simple/header';
import Logout from './components/simple/logout';
import Footer from './components/simple/footer';
// import Logout from './components/complex/logout';
import { UsuarioContext } from './usuarioContext';




const App = ()=> {

  const {usuario, usuarioSet} = useContext(UsuarioContext);

  useEffect(()=>{
    // usuarioSet({
    //   usuarioid:10,
    //   nombre:"Andres",
    //   apellido:"Kouvach",
    //   grupos:[2,34]     
    // });
    usuarioSet({});

    // voy a ver si hay un usuario ya logueado..

    let t = window.localStorage.getItem("usuario");
    if(t){
      let usu = JSON.parse(t);
      // console.log("usuario ya existente:", usu);
      usuarioSet(usu);

    }

  },[])

  // console.log(React.version);
  return (
    <div className="w3-container w3-padding">

      <Router>

          <div className="w3-row w3-mobile">
            <Header />
          </div>

          <div className="w3-row">
            <Routes>
            <Route path="/cursos" element={<Cursos />}/>
            <Route path="/biblioteca" element={<Biblioteca/>}/>
              <Route path="/login" element={<Login />} />              
              <Route path="/logout" element={<Logout />} />  
              <Route path="/registro" element={<Registro />} />                
              <Route path="/usuarios/:usuarioid" element={<Usuario />} />            
              <Route path="/dashboard/:id" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/ramas/:rama" element={<Rama />} />
              <Route path="/ramas/*" element={<Ramas />} />
              <Route path="/nosotros" element={<Nosotros />} />
              <Route path="/contacto" element={<Contacto />} />
              <Route path="/" element={<Home />} />
            </Routes>
          </div> 

          <div className="w3-row">
            <Footer />
          </div>
      </Router> 

    </div>


  );
}

export default App;
