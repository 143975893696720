import React , {useState} from 'react';
import PropTypes from 'prop-types';
import Select from '../base/select';




const Genero = ( {
                Titulo="Pais",
                Id="pais",
                Valor="AR",
                ValorSet=()=>console.log("falta definir la función para actualizar pais")
            } )=>{

    let [genero, generoSet]=useState(Valor)
    let [error, errorSet] = useState(null);

    
    
    
    // setTimeout(()=>{
    //     paisesSet(data);
    //     // console.log("datos traidos en useEffect", data);
    //     isLoadingSet(false);

    // },5000);


    const actualizarValor = (nuevoValor)=>{
        generoSet(nuevoValor);
        ValorSet(nuevoValor);
    }

    if(error){
        return <div className="w3-panel we-red">
            Error: {error.message}
        </div>;
    } else  {


        return (
            <div className='w3-container'>
                { 
                    <Select  
                        Id={Id} 
                        Titulo={Titulo}
                        Clave = "id"
                        Descripcion = "descripcion"
                        Valor={genero}
                        ValorSet={actualizarValor}
                        Valores={[{"id":"M","descripcion":"Masculino"},{"id":"F", "descripcion":"Femenino"}]}
                        Placeholder="Género"
                    />
                }
            </div>
        );
    }


}

Genero.propTypes = {
    Titulo: PropTypes.string,
    Id: PropTypes.string.isRequired,
    Valor: PropTypes.string,
    ValorSet: PropTypes.func
}

export default Genero;