import React, {Fragment, useState } from 'react';
import Form from '../base/form';
import Email from '../simple/email';

import Nombre from '../simple/nombre';
import Apellido from '../simple/apellido';
// import Hidden from '../simple/hidden';
import Password from '../simple/password';
import MiBotonEnviar from '../simple/miBotonEnviar';
import Pais from '../simple/pais';
import BD from '../context/bd';
import MiFecha from '../simple/miFecha';
import Genero from '../simple/genero';
// import Utils from '../generales/utils';



function Registro(){
    // const [usuarioid, usuarioidSet] = useState("0");
    const [fecha_nac, fecha_nacSet] = useState('');
    const [genero, generoSet] = useState('');
    const [nombre, nombreSet] = useState('');
    const [apellido, apellidoSet] = useState('');
    const [email, emailSet] = useState('');
    const [pass, passSet] = useState('');
    const [pass2, pass2Set] = useState('');

    const [paisid, paisidSet] = useState('AR');
    
    const [errores, erroresSet] = useState([]);
    
    const hasError = (clave) =>{
        let rta = errores.indexOf(clave) !== -1;
        // console.log("analizando errores de ", clave, rta);
        return rta;
    }

    const verificarDatos = ()=>{
        let errors = [];

        // console.log("verificando datos...");

        if(nombre===""){
            errors.push("nombre");
        }
        if(apellido===""){
            errors.push("apellido");
        }
        if(pass===""){
            errors.push("pass");
        }
        if(pass2===""){
            errors.push("pass2");
        }
        if(pass!==pass2){
            errors.push("pass");
            errors.push("pass2");
        }
        if(email===""){
            errors.push("email");
        }
        if(fecha_nac===""){
            errors.push("fecha_nac");
        }

        erroresSet(errors);

        if(errors.length>0){
            // console.log("Hay errores");
            return false;
        }
        return true;
    }

    const enviarFormulario = (ev)=>{
        ev.preventDefault();
        
        if(!verificarDatos()){
            return false;
        }

        // console.log("enviando datos");

        
        // console.log("verificando datos...");
        const data = new FormData(ev.target);
        const payload = Object.fromEntries(data.entries());
        // console.log("enviando..",payload);
        BD.enviar(`usuarios/create`,payload)
        .then( data => {
            console.log("respuesta:",data);
            if (data.ok){
                //todo bien
            }
        })
        .catch ( err => console.log("error: ", err));

    }

    return (
        <Fragment>

        <h1>Registrese</h1>
 
        <Form Id="RegistroUsuario" OnSubmit={enviarFormulario}>

                {/* <Hidden 
                    Id="usuarioid" 
                    Valor={usuarioid} 
                    ValorSet={usuarioidSet}
                /> */}


                    <Nombre 
                    Titulo=""
                    Id="nombre" 
                    TieneErrores = {hasError("nombre")}
                    PlaceHolder="Ingrese su nombre" 
                    Valor={nombre} 
                    ValorSet={nombreSet}
                />

                    <Apellido 
                    Titulo=""
                    Id="apellido" 
                    TieneErrores = {hasError("apellido")}
                    PlaceHolder="Ingrese su apellido" 
                    Valor={apellido} 
                    ValorSet={apellidoSet}
                />
                
                    <Email 
                    Titulo=""
                    Id="email" 
                    TieneErrores = {hasError("email")}
                    PlaceHolder="Ingrese su correo" 
                    Valor={email} 
                    ValorSet={emailSet}
                />

                                
                <MiFecha 
                    Titulo="Fecha Nac."
                    Id="fecha_nac" 
                    TieneErrores = {hasError("fecha_nac")}
                    PlaceHolder="Fecha de nacimiento" 
                    Valor={fecha_nac} 
                    ValorSet={fecha_nacSet}
                />

                
                <Genero 
                    Titulo="Genero"
                    Id="genero" 
                    TieneErrores = {hasError("genero")}
                    PlaceHolder="" 
                    Valor={genero} 
                    ValorSet={generoSet}
                />

                <Password 
                    Titulo=""
                    Id="pass" 
                    TieneErrores = {hasError("pass")}
                    PlaceHolder="Ingrese su contraseña" 
                    Valor={pass} 
                    ValorSet={passSet}
                />

                <Password 
                    Titulo=""
                    Id="pass2" 
                    TieneErrores = {hasError("pass2")}
                    PlaceHolder="Repita su contraseña" 
                    Valor={pass2} 
                    ValorSet={pass2Set}
                />


                <Pais
                Id='paisid'
                Valor={paisid}
                ValorSet={paisidSet}
                />



                <hr />
                <MiBotonEnviar FormId="RegistroUsuario" />

        </Form>

        </Fragment>
  );
};

export default Registro;