import React, {Fragment, useState, useContext } from 'react';
import {Link, useNavigate} from 'react-router-dom';

import Form from '../base/form';
import Email from '../simple/email';
import Password from '../simple/password';
import MiBotonEnviar from '../simple/miBotonEnviar';
import Loading from '../simple/loading';
import Alert from '../simple/alert';

import BD from '../context/bd';
import { UsuarioContext } from '../../usuarioContext';


const Login = ()=>{
    
    let navigate=useNavigate();
    const {usuario, usuarioSet} = useContext(UsuarioContext)
    const [email, emailSet] = useState('');
    const [pass, passSet] = useState('');
    const [isloading, isloadingSet] = useState(false);
    const [alert, alertSet] = useState({Texto:'', Clase:'w3-hide'});
    const [errores, erroresSet] = useState([]);

    const verificarDatos = ()=>{
        let errors = [];

        // console.log("verificando datos...");

        if(email===""){
            errors.push("email");
        }
        if(pass===""){
            errors.push("pass");
        }
        
        erroresSet(errors);

        if(errors.length>0){
            // console.log("Hay errores");
            return false;
        }
        return true;
    }

    const hasError = (clave) =>{
        let rta = errores.indexOf(clave) !== -1;
        // console.log("analizando errores de ", clave, rta);
        return rta;
    }
    const verificarFormulario = (ev)=>{
        ev.preventDefault();


        if(!verificarDatos()){
            alertSet({Texto:"revisar errores ", Clase:"w3-yellow"});
            return false;
        }


        const data = new FormData(ev.target);
        const payload = Object.fromEntries(data.entries());
        // console.log("verificando datos...", ev.target,data.entries());
        // console.log("enviando..",payload);
        
        let miUrl = `signin`;
        let method = "POST";

        
        isloadingSet(true);

        BD.enviar(miUrl,payload, method)
        .then( data => {
            isloadingSet(false);
            if(data.ok){
                alertSet({Texto:"Ingreso validado", Clase:"w3-green"});
                let clone=  Object.assign({},usuario);
                console.log(data.payload);
                clone.jwt = data.payload;
                clone.email = email;
                usuarioSet(clone);
                window.localStorage.setItem("usuario", JSON.stringify(clone));

                navigate("/",{replace:true});

            }
           

        })
        .catch ( err => {
            // console.log("error: ", err);
            alertSet({Texto:"todo mal", Clase:"w3-red"});
            isloadingSet(false);
        });


        alertSet({Texto:'todo bien', Clase:'w3-green'})

    }

    if(isloading){
        return <Loading />
    } else {

        return (
            <Fragment>


                <h1>Ingrese</h1>
                <Alert Texto={alert.Texto} Clase={alert.Clase} />
    
                <Form Id="loginUsuario" OnSubmit={verificarFormulario}>


                    <Email 
                        Titulo=""
                        Id="email" 
                        PlaceHolder="Ingrese su correo" 
                        Valor={email} 
                        ValorSet={emailSet}
                        TieneErrores = {hasError("email")}
                    />

                    <Password 
                        Titulo=""
                        Id="pass" 
                        PlaceHolder="Ingrese su contraseña" 
                        Valor={pass} 
                        ValorSet={passSet}
                        TieneErrores = {hasError("pass")}
                    />

                    <hr />
                    <MiBotonEnviar FormId="loginUsuario" /> 
            

                </Form>

                <br />

                Si no se encuentra registrado: <Link to="/registro"><u>Registrese</u></Link>

                {/* <pre>{JSON.stringify(usuario,3,3)}</pre> */}
            </Fragment>
        );
    }
};

export default Login;