import React , {useState }from 'react';
import PropTypes from 'prop-types';
import Label from '../base/label';
import InputText from '../base/inputtext';

const MiFecha = ({
                    Titulo="Fecha",
                    Id="fecha",
                    TieneErrores=false,
                    PlaceHolder="introduzca su fecha", 
                    Valor="", 
                    ValorSet=(valor)=>{
                        console.log("falta agregar la función para asignación fecha.");
                    }
                } )=>{

    let [fecha, fechaSet] = useState(Valor);

    function actualizarFecha(nuevoValor){
        fechaSet(nuevoValor);
        ValorSet(nuevoValor);
    }

    return (
        <div className="w3-container w3-row">
            <div className="w3-quarter">
                <Label Texto={Titulo} HtmlFor={Id} />
            </div>
            <div className="w3-threequarter">
                <InputText 
                    Id={Id} 
                    Tipo="date" 
                    TieneErrores = {TieneErrores}
                    ErrorMessage="Debe completar la fecha"
                    PlaceHolder={PlaceHolder} 
                    Valor={fecha} 
                    ValorSet={actualizarFecha}  
                />
            </div>
    
        </div>

    );
}

MiFecha.propTypes = {
    Titulo: PropTypes.string.isRequired,
    Id : PropTypes.any.isRequired,
    TieneErrores : PropTypes.bool,
    PlaceHolder : PropTypes.string,
    Valor : PropTypes.string.isRequired,
    ValorSet: PropTypes.func    
}

export default MiFecha;