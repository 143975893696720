import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/w3.css';
import './css/miCss.css';
import App from './App'
// import EnConstruccion from './components/complex/enconstruccion';
import { UsuarioProvider } from './usuarioContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>

    <UsuarioProvider>
        <App />
    </UsuarioProvider>


  </React.StrictMode>
);

