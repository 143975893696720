import React, {useState} from 'react';
import PropTypes from 'prop-types';


const Select = ({
                    Id="falta Id",
                    Valor="",
                    Clave="id",
                    Descripcion="descripcion",
                    Valores=[],
                    Placeholder="Seleccione...",
                    ValorSet=()=>console.log("falta definir el handler para el select")
                })=>{
    let [miValor, miValorSet] = useState(Valor);

    const actualizarValor = (nuevoValor)=>{
        miValorSet(nuevoValor);
        ValorSet(nuevoValor);
    }
  


    // if(!Array.isArray(Valores)){
    //     return (
    //         <Loading />
    //     )
    // } else {



        return (
            <select 
                id={Id} 
                name={Id}
                className="w3-select" 
                value = {miValor}
                onChange={(ev)=>actualizarValor(ev.target.value)}
            >
                <option key="0" value="0">{Placeholder}</option>

            {Valores.map((item) =>{
                // console.log(item[Clave],miValor);
                // if(item[Clave]===miValor){
                //     return (
                //         <option key={item[Clave]} value={item[Clave]} selected>
                //             {item[Descripcion]}
                //         </option>
                //     )
                // }
                return (
                    <option key={item[Clave]} value={item[Clave]}>
                        {item[Descripcion]}
                    </option>
                )
        })}

            </select>
        );
    // }
}

Select.propTypes = {
    Id: PropTypes.string.isRequired,
    Valor: PropTypes.string,
    Clave: PropTypes.string.isRequired,
    Descripcion: PropTypes.string.isRequired,
    Placeholder: PropTypes.string,
    Valores: PropTypes.array.isRequired,
    ValorSet : PropTypes.func
}

export default Select;