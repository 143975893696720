import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Label from './label';

const Textarea = ({
                Titulo = "",
                Id="",
                PlaceHolder = "Placeholder",
                Rows = 3,
                Readonly = false,
                Required = false,
                Valor = "",
                ValorSet = () => {
                  console.log("falta definir funcion set de textarea");
                }
            })=>{
    return (
        <Fragment>

            <Label Texto={Titulo} HtmlFor={Id} />

                <textarea 
                placeholder={PlaceHolder}
                rows = {Rows}
                readOnly = {Readonly}
                required = {Required}
                className='w3-input w3-border'
                id={Id}
                name={Id}
                value={Valor}
                onChange={(e) => ValorSet(e.target.value)}
                >
                </textarea>



        </Fragment>

    );
}

Textarea.propTypes = {
    Titulo : PropTypes.string.isRequired,  
    Id : PropTypes.string.isRequired,  
    PlaceHolder: PropTypes.string,
    Rows : PropTypes.number,
    Readonly : PropTypes.bool,
    Required : PropTypes.bool,
    Valor: PropTypes.any.isRequired,
    ValorSet: PropTypes.func.isRequired,
}

export default Textarea;