import React ,{useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as Constants from '../context/constants';

const Alert = ({
  Clase="",
  Titulo="",
  Texto=""
})=>{

  const [visible, visibleSet]=useState(true);

  useEffect(()=>{
    // console.log("Actualizando visibe a true");
    visibleSet(true);

  },[Texto,Titulo]);

  if (visible){
    // console.log("visible:",visible);
    setTimeout(()=>{
      visibleSet(false);
      // console.log("le cambié el valor", false)                    
    },Constants.DELAYALERTS);


    return (
        <div className={'w3-panel '+Clase}>
          <h2>{Titulo}</h2>
          <h6>{Texto}</h6>
        </div>
    )
  } else {
    return <div></div>
  }

}

Alert.propTypes = {
  Clase: PropTypes.string,
  Titulo: PropTypes.string,
  Texto: PropTypes.string.isRequired
}
export default Alert;
