
import React, {Fragment, useState, useEffect} from 'react';
import Loading from './../simple/loading';
// import { Link } from 'react-router-dom';

import IMG1 from "../../images/lobatos.jpg";
import IMG2 from "../../images/scouts.jpg";
import IMG3 from "../../images/raiders.png";
import IMG4 from "../../images/rovers.jpg";
import IMG5 from "../../images/grupo1.jpeg";
import Slides from '../simple/slides';



function Home(){
    const [isLoading, isLoadingSet] = useState(false);
    const [slides, slidesSet]=useState([]);


    useEffect(()=>{
        slidesSet([
            {"id":1, "imagen":IMG1, "link":"/ramas/lobatos", "alt":"Lobatos", "texto":"Lo principal para los mas chicos"},
            {"id":2, "imagen":IMG2, "link":"/ramas/scouts","alt":"Scouts", "texto":"Lo principal para los scouts"},
            {"id":3, "imagen":IMG3, "link":"/ramas/raider","alt":"Raiders", "texto":"Lo principal para los raiders"},
            {"id":4, "imagen":IMG4, "link":"/ramas/rovers","alt":"Rovers", "texto":"Lo principal para los Rovers"},
            {"id":5, "imagen":IMG5, "link":"","alt":"Comunidad", "texto":"La importancia de la actividad grupal"}            
        ]);

    },[]);



    if(isLoading){
        return <Loading />
    } else {
        return(
            <Fragment>
               
                <h1>Bienvenidos!</h1>

                <Slides slides={slides}  />

            </Fragment>
        )
    }


}

export default Home;