// import * as Constants from '../context/constants';
import React, {useRef} from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const Utils = {

    capitalize : (word)=>{
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
    },
    getFechaHora : (formato="")=>{
        //voy a buscar estos datos..
        //Antes, teng que hashear la password (porque es lo que está en la BD)
// const data = new FormData(ev.target);
        // const payload = Object.fromEntries(data.entries());
        let ahora = new Date();
        // let output = String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + date.getFullYear();
        return ahora;
//         var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
// var today  = new Date();

// console.log(today.toLocaleDateString("en-US")); // 9/17/2016
// console.log(today.toLocaleDateString("en-US", options)); // Saturday, September 17, 2016
// console.log(today.toLocaleDateString("hi-IN", options)); // शनिवार, 17 सितंबर 2016


    },
    confirma :  (titulo="Esta seguro?", mensaje="realmente seguro" )=>{
        let rta = false;
        // console.log("entre al confirm");

        return new Promise((resolve,reject)=>{
            confirmAlert({
                title: titulo,
                message: mensaje,
                afterClose : ()=>{
                    // console.log("después de cerraar. rta=",rta);
                    resolve(rta);
                    // clearInterval(myInterval);
                },
                buttons: [
                {
                    label: 'Yes',
                    onClick: () =>{
                        rta=1;
                        
                    } 
                },
                {
                    label: 'No',
                    onClick: () => {
                        rta=2;
                        
                    }
                }
                ]
            });
        });
    },
    useInterval : (callback, delay) => {
        const savedCallback = React.useRef();
      
        React.useEffect(() => {
          savedCallback.current = callback;
        }, [callback]);
      
        React.useEffect(() => {
          const tick = () => {
            savedCallback.current();
          }
          if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
          }
        }, [delay]);
    }
}

export default Utils;