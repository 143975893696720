import React from "react";
import PropTypes from "prop-types";
import Button from "../base/button";

const MiBotonEnviar = ({
  FormId = "",
  Click = ()=>console.log("click default"),
  Clase = "w3-round-xxlarge w3-green",
  Texto = "Enviar",
  Id = "",
  Valor = ""
}) => {
  return <Button 
          Clase={"w3-button w3-round " + Clase} 
          Tipo="submit" 
          Texto={Texto} 
          FormId={FormId} 
          Id={Id} 
          Valor={Valor}
          OnClick={Click}
          />;
};

MiBotonEnviar.propTypes = {
  FormId: PropTypes.string.isRequired,
  Texto: PropTypes.string,
  Clase: PropTypes.string,
  Id: PropTypes.string,
  Valor: PropTypes.string,
  Click: PropTypes.func
};

export default MiBotonEnviar;
