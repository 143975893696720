import {  Link } from "react-router-dom";
import React from 'react'

function MenuItem(props){
    return (
        <Link to={props.link} 
          className="w3-bar-item  sinunderline w3-mobile w3-animate-zoom w3-padding-small w3-border-left w3-right w3-hover-teal">
          {props.text}
        </Link>
    )
}

export default MenuItem;

