import React, {Fragment, useState } from 'react';
import Form from '../base/form';
import Email from '../simple/email';

import Nombre from '../simple/nombre';
import Apellido from '../simple/apellido';
// import Hidden from '../simple/hidden';
import Tema from '../simple/tema';
import MiBotonEnviar from '../simple/miBotonEnviar';
import Mensaje from '../simple/mensaje';
import BD from '../context/bd';

// import Utils from '../generales/utils';



const Contacto = ()=>{
    // const [usuarioid, usuarioidSet] = useState("0");

    const [nombre, nombreSet] = useState('');
    const [apellido, apellidoSet] = useState('');
    const [email, emailSet] = useState('');
    const [tema, temaSet] = useState('');
    const [mensaje, mensajeSet] = useState('');
    
    const [errores, erroresSet] = useState([]);
    
    const hasError = (clave) =>{
        let rta = errores.indexOf(clave) !== -1;
        return rta;
    }

    const verificarDatos = ()=>{
        let errors = [];

        // console.log("verificando datos...");

        if(nombre===""){
            errors.push("nombre");
        }
        if(apellido===""){
            errors.push("apellido");
        }
        if(tema===""){
            errors.push("titulo");
        }
        if(mensaje===""){
            errors.push("mensaje");
        }

        erroresSet(errors);

        if(errors.length>0){
            // console.log("Hay errores");
            return false;
        }
        return true;
    }

    const enviarFormulario = (ev)=>{
        ev.preventDefault();
        
        if(!verificarDatos()){
            return false;
        }

        // console.log("verificando datos...");
        const data = new FormData(ev.target);
        const payload = Object.fromEntries(data.entries());
        // console.log("enviando..",payload);
        BD.enviar(`usuarios/create`,payload)
        .then( data => console.log("respuesta:",data))
        .catch ( err => console.log("error: ", err));

    }

    return (
        <Fragment>


        <h1>Contáctenos</h1>

        <p>
            Ante cualquier consulta por favor, no dude en ponerse en contacto con nosotros.
        </p>
        <p>
            Para facilitar la atención de sus inquietudes, tenemos las siguientes vías de contacto:             
        </p>
        <ul>
            <li>
                <b>grupos@usca.org.ar</b>: Atención de grupos ya inscriptos.
            </li>
            <li>
                <b>inscripciones@usca.org.ar</b>: Atención de grupos no inscriptos.
            </li>
            <li>
                <b>info@usca.org.ar</b>: Atención general de consultas.
            </li>

        </ul>
 
 <p>
    Para culquier otra cuestión , por favor, ingrese sus datos, el tema de consulta y el mensaje así podemos derivar su inquietud de manera adecuada.
 </p>

            <fieldset>
 
        <Form Id="EnvioDeEmail" OnSubmit={enviarFormulario}>


                <Nombre 
                    Titulo=""
                    Id="nombre" 
                    TieneErrores = {hasError("nombre")}
                    PlaceHolder="Ingrese su nombre" 
                    Valor={nombre} 
                    ValorSet={nombreSet}
                />

                <Apellido 
                    Titulo=""
                    Id="apellido" 
                    TieneErrores = {hasError("apellido")}
                    PlaceHolder="Ingrese su apellido" 
                    Valor={apellido} 
                    ValorSet={apellidoSet}
                />
                
                <Email 
                    Titulo=""
                    Id="email" 
                    TieneErrores = {hasError("email")}
                    PlaceHolder="Ingrese su correo" 
                    Valor={email} 
                    ValorSet={emailSet}
                />

                <hr />



                <Tema 
                    Titulo=""
                    Id="tema" 
                    TieneErrores = {hasError("tema")}
                    PlaceHolder="Tema de referencia" 
                    Valor={tema} 
                    ValorSet={temaSet}
                    />
                <Mensaje 
                    Titulo=""
                    Id="mensaje" 
                    TieneErrores = {hasError("mensaje")}
                    PlaceHolder="Ingrese su mensaje" 
                    Valor={mensaje} 
                    ValorSet={mensajeSet}
                    />


                <hr />
                <MiBotonEnviar FormId="EnvioDeEmail" Texto='Enviar correo'/>

        </Form>
                    </fieldset>

        </Fragment>
  );
};

export default Contacto;