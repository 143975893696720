
import React, {useContext, useEffect} from 'react';
import { UsuarioContext } from '../../usuarioContext';
import { useNavigate } from 'react-router-dom';

const Logout=()=>{
  const {usuario, usuarioSet} = useContext(UsuarioContext)
  let navigate=useNavigate();

  useEffect( ()=>{
    usuarioSet({});

    window.localStorage.removeItem("usuario");

    navigate("/");

  },[]);

    return(

      <div className="w3-container">
        <h5>Saliendo...</h5>
      </div>


    )
}

export default Logout

