import React , {Fragment} from 'react';

function Footer(props){
  const currentYear = new Date().getFullYear();
    return (
      <Fragment>
        <hr />
        <p className="w3-row miFooter">
          Copyright &copy; 2022 - {currentYear} - USCA
        </p>
      </Fragment>
    )
}

export default Footer;
