import MenuItem from './menu-item';
import React,  { Fragment, useContext, useEffect} from 'react';
import { UsuarioContext } from '../../usuarioContext';
import Logo from './logo';

const Menu=(props)=>{
  const {usuario, usuarioSet} = useContext(UsuarioContext);
  
  useEffect(()=>{
    // console.log("usuario recibido:",usuario);
  },[]);

  const nada=()=>{
    return 0;
  }

  const aparecer=()=>{
    console.log("aparecer");

    var x = document.getElementById("menucolapsado");
    if (x.className.indexOf("w3-show") === -1) {
      x.className += " w3-show";
    } else { 
      x.className = x.className.replace(" w3-show", "");
    }

  }

  
    return(
      <Fragment>
        <div className='w3-row'>
          <div className='w3-quarter'>
            <Logo /> 
          </div>
          <div className='w3-threequarter'>
            <nav className="w3-bar w3-hide-small" id="myMenu">              
              {
                (usuario.email && usuario.email !== "") 
                && <MenuItem text='Salir' link='/logout'/> 
              }
              {
                (!usuario.email || usuario.email === "") 
                && <MenuItem text='Ingrese' link='/login'/> 
              }

              {
                usuario.email && usuario.email !== "" 
                && <MenuItem text='Dashboard' link='/dashboard'/>            
              }
              
              <MenuItem text='Contacto' link='/contacto'/>           
              <MenuItem text='Biblioteca' link='/biblioteca'/>           
              <MenuItem text='Cursos' link='/cursos'/>           
              <MenuItem text='Educación' link='/ramas'/> 
              <MenuItem text='Nosotros' link='/nosotros'/>           
      

            </nav>

            <a className="w3-bar-item w3-button w3-right w3-hide-large w3-hide-medium" 
              onClick={aparecer}>&#9776;</a>

            <nav id="menucolapsado" className="w3-bar-block w3-hide w3-hide-large w3-hide-medium ">
              {
                (usuario.email && usuario.email !== "") 
                && <MenuItem text='Salir' link='/logout'/> 
              }
              {
                (!usuario.email || usuario.email === "") 
                && <MenuItem text='Ingrese' link='/login'/> 
              }

              {
                usuario.email && usuario.email !== "" 
                && <MenuItem text='Dashboard' link='/dashboard'/>            
              }
              
              <MenuItem text='Biblioteca' link='/Biblioteca'/>           
              <MenuItem text='Contacto' link='/contacto'/>           
              <MenuItem text='Educación' link='/ramas'/> 
              <MenuItem text='Nosotros' link='/nosotros'/>           
            </nav>              


            </div>
          </div>
        </Fragment>
    )
}

export default Menu


