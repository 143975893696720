import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';


function Ramas(props){


    return (
        <Fragment>
            <h1>Educación</h1>

            <h2>Nuestro sistema educativo</h2>
            <div class="w3-panel w3-leftbar w3-light-grey">
                <p class="w3-xlarge w3-serif">
                <i>“La educación es el arma más poderosa que puedes usar para cambiar el mundo"</i>
                </p>
                <p>Nelson Mandela</p>
            </div>

            <p>
            Ello es todo para nuestra niñez y juventud scout, y por ello, trabajamos por un mundo mejor, más justo y solidario, donde el cuidado del medio ambiente, el respeto a los derechos humanos y el entendimiento entre las personas y los pueblos, sea una realidad y no un mero evento discursivo.-
            </p>


        <p>
                Nuestra organización (U.S.C.A.)-UNION SCOUT CATOLICOS ARGENTINOS -plasmada en “Las actividades que realizan los “scouts”, ayudan a descubrir dones y habilidades personales, forjándonos en entrañables jornadas e inolvidables “campamentos de Grupos, de Ramas, Distritales, Regionales   (reuniones anuales) y los tan esperados jamboreés, donde la camaradería, la habilidad creativa y la voluntad para servir, forja el carácter de cada participante y define su destino de vida.-
            </p>
            <p>
            Nuestra pertenencia al movimiento, habilita para participar en las reuniones que se realizan una vez por semana para “afilar el espíritu del buen scout”, dispuesto a brindar ayuda y a desarrollar proyectos solidarios para otros scouts y para la sociedad en su conjunto. Además, todos los sábados a la tarde, el grupo se ejercita en todos los aspectos de la educación informal que conllevan al desarrollo integral de la persona, como así también de diferentes técnicas de supervivencia al aire libre y en la elección de lugares aptos para acampar. Aprenden a orientarse de día o de noche, practican para confeccionar diferentes tipos de nudo, construcciones con troncos, primeros auxilios y técnicas de salvamento, observación de señales, lectura de signos, acecho y rastreo, pero seguramente la actividad más emocionante que realizan son los campamentos, donde la adrenalina fluye viviendo al aire libre, valiéndose de sus propios medios y donde durante la noche, frente al clásico “fogón” se cuentan historias, se canta y se goza con  la compañía de los demás.
            </p>
            <h2>Progresión</h2>
            <p>La progresión se divide en ramas y estas se configuran en función de rangos de edades.  Así, los niños y niñas de 7 a 11 años integran la Rama <Link to="lobatos">Lobatos</Link>, constituyendo las distintas Manadas, viviendo la aventura y la fantasía de la Selva, adquiriendo así, su rol dentro de la familia scout y vivenciando las experiencias del mundo que los rodea, aprendiendo y ejerciendo la ley de la Manada se une a las prácticas de los distintos animales de la Selva, basadas en el respeto y la comunión con el universo. 
            </p> 
            <p>
            Quienes están en la pubertad o en la primera adolescencia (11 a 14 años) integran la Rama <Link to="scouts">Scouts</Link> del Grupo; manifestándose dentro del Sistema de Patrullas; jugando el gran juego de los Caballeros medievales; adquiriendo valores y virtudes del mundo de los hombres, vivenciando ahora su Fe, la ley, su promesa, el honor, principios y virtudes; aquellas facetas de los hombres que lo acompañaran a lo largo de toda su vida.
            </p>
            <p>
                Ya en la adolescencia intermedia (14 a 17 años), forman parte de la Rama <Link to="raiders">Raider</Link>, habiendo adquirido su investidura Raider desarrollan su sentido de responsabilidad y compromiso dentro de la Tropa , profundizan su pertenencia a través del honor y la crítica  propia de la juventud, enfrentando los riesgos de la aventura y la sana competencia con sus pares, siempre bajo su Ley y su Promesa. 
            </p>
            <p>
                Los jóvenes (17 a 21 años) conforman la Rama <Link to="rovers">Rover</Link> integrando el Clan  Rover, en la cual lo jóvenes se preparan para su vida adulta, ahora comienzan a poner en práctica su responsabilidad ,adquiriendo el valor del Servicio hacia sus hermanos Scout y el resto de la sociedad misma, brindándoles a través de la Partida Rover,  la facultad de enfrentar todos y cada uno de los escollos que le depare su vida como hombre dentro de la sociedad a la cual pertenece , sirviéndose continuamente de la libre elección que su Cayado representa.
             </p>


        </Fragment>
    );
}

export default Ramas;