
import React, {Fragment, useState, useEffect, useContext } from 'react';
import Form from '../base/form';
import Email from '../simple/email';
import Nombre from '../simple/nombre';
import Apellido from '../simple/apellido';
import Hidden from '../simple/hidden';
import Password from '../simple/password';
import MiBotonEnviar from '../simple/miBotonEnviar';
import Pais from '../simple/pais';
import BD from '../context/bd';
import { useParams, useNavigate } from "react-router-dom";
import Loading from '../simple/loading';
import Alert from '../simple/alert';
import Utils from '../generales/utils';
import { UsuarioContext } from '../../usuarioContext';


const Usuario = (props)=>{

    const {usuario, usuarioSet} = useContext(UsuarioContext)
    const [alert, alertSet] = useState({Texto:'', Clase:'w3-hide'});
    const [accion, accionSet] = useState('');
    const [isloading, isloadingSet] = useState(true);
    const [usu, usuSet] = useState(useParams().usuarioid);
    const [usuarioid, usuarioidSet] = useState(0);
    const [nombre, nombreSet] = useState('');
    const [apellido, apellidoSet] = useState('');
    const [email, emailSet] = useState('');
    const [pass, passSet] = useState('');
    const [pass2, pass2Set] = useState('');

    const [paisid, paisidSet] = useState('');
    
    const [errores, erroresSet] = useState([]);

    let navigate = useNavigate();

      // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        //si no esta logueado... mandarlo a login..
        if(!usuario.jwt || usuario.jwt===""){
            navigate("/login");
        }
        // Update the document title using the browser API
        // console.log("usuario recibido: ", usu);
        if(usu>0){
            /* voy a intentar buscar los datos de este usuario */
            actualizarDatosDeUsuario();            
        } else{
            isloadingSet(false);
        }


    },[]);




    const actualizarDatosDeUsuario= async ()=>{
        await BD.traer("usuarios/"+usu)
        .then(rta => {
            // console.log("datos del usuario: ",rta);
            /* Actualizao los valores del componente con lo que trajo de la base de datos */
            usuarioidSet(rta.usuario.usuarioid);
            nombreSet(rta.usuario.nombre);
            apellidoSet(rta.usuario.apellido);
            emailSet(rta.usuario.email);
            passSet(rta.usuario.pass);
            pass2Set(rta.usuario.pass);
            paisidSet(rta.usuario.paisid);
        } )
        .catch( err => console.log("error en la llamada a traer",err))
        .finally(()=>isloadingSet(false));
    }

    const hasError = (clave) =>{
        let rta = errores.indexOf(clave) !== -1;
        // console.log("analizando errores de ", clave, rta);
        return rta;
    }

    const verificarDatos = ()=>{
        let errors = [];

        // console.log("verificando datos...");

        if(nombre===""){
            errors.push("nombre");
        }
        if(apellido===""){
            errors.push("apellido");
        }
        if(pass===""){
            errors.push("pass");
        }
        if(pass2===""){
            errors.push("pass2");
        }
        if(pass!==pass2){
            errors.push("pass");
            errors.push("pass2");
        }
        if(email===""){
            errors.push("email");
        }
        erroresSet(errors);

        if(errors.length>0){
            // console.log("Hay errores");
            return false;
        }
        return true;
    }

    const enviarFormulario = async (ev)=>{
        ev.preventDefault();

        let miUrl = "";
        let method = "";

        if(!verificarDatos()){
            alertSet({Texto:"revisar errores ", Clase:"w3-yellow"});
            return false;
        }

        // console.log("enviando datos con los siguientes datos de usuario: ", usuario);

        switch (accion) {
            case 'agregar':
              //Declaraciones ejecutadas cuando el resultado de expresión coincide con el valor1
            //   console.log("agregar");
              miUrl = `usuarios/0`;
              method = "POST";
              break;
            case 'actualizar':
                let rtaAct = await Utils.confirma("Actualizar usuario...","esta seguro?");
                // console.log("actualizar, rta:",rtaAct);
                if(rtaAct>1){
                    return false;
                };
                // console.log("voy a actualizar... ");
                // console.log("actualizar");//Declaraciones ejecutadas cuando el resultado de expresión coincide con el valor2
              miUrl = `usuarios/${usuarioid}`;
              method = "PUT";
              break;
            case 'eliminar':
                let rtaDel = await Utils.confirma("Eliminando usuario...","esta seguro?");
                // console.log("eliminar, rta:",rtaDel);
                if(rtaDel>1){
                    return false;
                };
                // console.log("voy a eliminar... ");

                miUrl = `usuarios/${usuarioid}`;
                method="DELETE";
                //Declaraciones ejecutadas cuando el resultado de expresión coincide con valorN
                break;
            default:
              //Declaraciones ejecutadas cuando ninguno de los valores coincide con el valor de la expresión
            //   console.log("parametro de swithc no encontrado");
              return false;
          }
        
        const data = new FormData(ev.target);
        const payload = Object.fromEntries(data.entries());
        // console.log("verificando datos...", ev.target,data.entries());
        // console.log("enviando..",payload);
        
        BD.enviar(miUrl,payload, method, usuario.jwt)
        .then( data => {
            // console.log("respuesta:",data);
            alertSet({Texto:"todo bien", Clase:"w3-green"});
            navigate("/");
        })
        .catch ( err => {
            // console.log("error: ", err);
            alertSet({Texto:"todo mal", Clase:"w3-red"});
        });


    }



    if(isloading){
        return <Loading />
    } else {
        // console.log("usu:", usu);

        return (
            <Fragment>


            <h1>{ (usu>0)? "Usuario:"+usu:"Registrese" } </h1>
    
            <Alert Texto={alert.Texto} Clase={alert.Clase} />

    
            <Form Id="RegistroUsuario" OnSubmit={enviarFormulario}>

                    <Hidden 
                        Id="usuarioid" 
                        Valor={usuarioid} 
                        ValorSet={usuarioidSet}
                    />


                        <Nombre 
                        Titulo=""
                        Id="nombre" 
                        TieneErrores = {hasError("nombre")}
                        PlaceHolder="Ingrese su nombre" 
                        Valor={nombre} 
                        ValorSet={nombreSet}
                    /> 

                        <Apellido 
                        Titulo="Apellido"
                        Id="apellido" 
                        TieneErrores = {hasError("apellido")}
                        PlaceHolder="Ingrese su apellido" 
                        Valor={apellido} 
                        ValorSet={apellidoSet}
                    /> 
                    
                        <Email 
                        Titulo="Email"
                        Id="email" 
                        TieneErrores = {hasError("email")}
                        PlaceHolder="Ingrese su correo" 
                        Valor={email} 
                        ValorSet={emailSet}
                    /> 

                    <Password 
                        Titulo="Contraseña"
                        Id="pass" 
                        TieneErrores = {hasError("pass")}
                        PlaceHolder="Ingrese su contraseña" 
                        Valor={pass} 
                        ValorSet={passSet}
                    />

                    <Password 
                        Titulo="Repita Contraseña"
                        Id="pass2" 
                        TieneErrores = {hasError("pass2")}
                        PlaceHolder="Ingrese su contraseña" 
                        Valor={pass2} 
                        ValorSet={pass2Set}
                    />


                    <Pais
                    Id='paisid'
                    Valor={paisid}
                    ValorSet={paisidSet}
                    />



                    <hr />

                    { 
                    (usu>0)  &&
                        <div className='w3-row'>
                            <MiBotonEnviar 
                            Clase = "w3-black"
                            Id="Accion1" 
                            Valor="actualizar" 
                            Texto="Actualizar" 
                            FormId="RegistroUsuario"
                            Click={()=>accionSet('actualizar')}
                            />
                            <MiBotonEnviar 
                            Clase="w3-red"
                            Id="Accion2" 
                            Valor="eliminar" 
                            Texto="Eliminar" 
                            FormId="RegistroUsuario" 
                            Click={()=>accionSet('eliminar')}
                            />                        
                        </div>
                    }
                    { !(usu>0) &&
                        <MiBotonEnviar 
                        Texto = "Registrarse"
                        Clase="w3-black" 
                        Id="Accion3" 
                        Valor="insertar" 
                        FormId="RegistroUsuario" 
                        Click={()=>accionSet('agregar')}
                        />
                    }
                     
                    
            </Form>

            </Fragment>
        );
    }
};

export default Usuario;
