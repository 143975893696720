import React, {useState} from 'react';
import Label from '../base/label';
import InputText from '../base/inputtext';
import PropTypes from 'prop-types';

const Apellido = ( {
        Titulo="Apellido",
        Id="apellido",
        TieneErrores=false,
        PlaceHolder="introduzca su Apellido", 
        Valor="", 
        ValorSet=()=>console.log("falta agregar la función para asignación en Apellido.")
    } )=>{

    let [apellido, apellidoSet] = useState(Valor);

    function actualizarApellido(nuevoValor){
        apellidoSet(nuevoValor);
        ValorSet(nuevoValor);
    }

    return (
        <div className="w3-container">
            <Label Texto={Titulo} HtmlFor={Id} />
            <InputText 
                Id={Id} 
                Tipo="text" 
                TieneErrores = {TieneErrores}
                ErrorMessage="Debe completar el apellido"
                PlaceHolder={PlaceHolder} 
                Valor={apellido} 
                ValorSet={actualizarApellido}  
            />
        </div>
    );
}

Apellido.propTypes = {
    Titulo: PropTypes.string.isRequired,
    Id : PropTypes.any.isRequired,
    TieneErrores: PropTypes.bool,
    PlaceHolder : PropTypes.string,
    Valor : PropTypes.string.isRequired,
    ValorSet: PropTypes.func
}

export default Apellido;