import React, {Fragment, useState,useEffect,useContext} from 'react';
import BD from '../context/bd';
import { UsuarioContext } from '../../usuarioContext'
// import image_edit from '../../images/iconos/pen-to-square-solid.svg'
import image_edit from '../../images/iconos/edit.png'



function Biblioteca(){
    // let user_context = useContext(UsuarioContext)
    const {usuario, usuarioSet} = useContext(UsuarioContext);
    let [libros, librosSet] = useState([]);
    let [librostotal, librostotalSet] = useState([]);
    let [temasNames, temasNamesSet] = useState([]);
    let [titulo,tituloSet] = useState("")
    
    let [ramas,ramasSet] = useState([])
    
    let ramasNames = ["Lobato", "Scout", "Raider", "Rover"];
    
    const buscarlibro = (e) => {
        tituloSet(e.target.value);
    }

    const filtroCheckbox = (e) => {
        if (e.target.checked){
            ramasSet(ramas.concat([e.target.value]))
        }else{
            const newArray = ramas.filter(element => {
                return element!== e.target.value
            })
            ramasSet(newArray)
        }
    }

    const filtrar = () => {
    
        let filtrado = librostotal.filter(item=>{
            let incluir = true;

            if(titulo!==""){
                let buscar = item.titulo.toLowerCase();
                let buscar2 = item.autor.toLowerCase();
                if(!buscar.includes(titulo.toLowerCase()) && 
                    !buscar2.includes(titulo.toLowerCase())){
                    incluir=false;
                }
            }

            if (incluir && ramas.length > 0){
                let encontrado = false;
                for(let i=0;i<ramas.length;i++){
                    if (item.ramas.join(",").toLowerCase().split(",").indexOf(ramas[i].toLowerCase())>-1){                
                        encontrado = true;
                        break;
                    }
                    if (item.temas.join(",").toLowerCase().split(",").indexOf(ramas[i].toLowerCase())>-1){                
                        encontrado = true;
                        break;
                    }
                }
                incluir = encontrado;
            }

            return incluir;
        });
        librosSet(filtrado)
    }
    useEffect( () => {
        filtrar()
            
    }, [titulo,ramas]);

    useEffect( () => {

        console.log("usuario:",usuario);

        BD.traer("cur_biblioteca/temas")
        .then((data)=>{
                temasNamesSet(data.payload.map(item=>{
                    return item.tema;
                }));
        })
        .catch( (err)=>{
            console.log(err);
        });


        BD.traer("cur_biblioteca")
        .then((data)=>{
                let rdo = data.payload.map(item=>{
                    let p = item;
                    p.ramas = JSON.parse(item.ramas);
                    p.temas = JSON.parse(item.temas);
                    return p;
                });

                librosSet(rdo);
                librostotalSet(rdo);
        })
        .catch( (err)=>{
            console.log(err);
        });

        

            
    }, []);

    
    const get_libros  = libros.map((libro,key) => {
       
        return <div key={key} className='card-book w3-hover-border-teal w3-leftbar w3-border-white'>
                <span  className='title-book'>
                    {libro.titulo}
                </span>
                <span  className='definition-card'>
                    <strong>Por:</strong> {libro.autor}
                </span>
                <span className='definition-card'>
                    <strong>Ramas:</strong> {libro.ramas.join(", ") }
                </span>
                <span className='definition-card definition-temas'>
                    <strong>Temas:</strong> {libro.temas.join(", ")}
                </span>
                <a className="link-book-card" href={libro.link} target='_blank' rel='noreferrer'>
                    <span>Leer Aqui </span>
                </a>
                {/* <Link
                    to='/editar_libro'>
                        <img src={image_edit} alt='edit-image' width="15px"/>
                </Link> */}
                <a className='link-edit-card' id='edit-icon' href='#' target='_blank' rel="noreferrer">
                    <span>
                        <img src={image_edit} alt='edit-image' width="15px"/>
                    </span>
                </a>
        </div>
    })
    // funcion que genera el filtro checkbox
    const librosNames = (nameList,idName) => {
        const libroLista = nameList.map((item,index) => {
            return <li key={index}>
                    <input 
                    className='w3-check' 
                    id={idName + (index+1)} 
                    value={item}
                    type={"checkbox"}
                    onChange={filtroCheckbox}
                    />
                    <label htmlFor={idName + (index+1)}> {item}</label>
            </li>
        });
        return libroLista
    }

    return (
        <Fragment>


        <h1>Biblioteca</h1>
        <div className='w3-container book-container'>
            <div className='section-filter w3-container'>
                <div>
                <span className='filter-text-head'>Filtrar: </span>
                {/* elemento de busqueda de libros */}
                    <input 
                        className='w3-input' 
                        name='buscar' 
                        onChange={buscarlibro} 
                        type='text' 
                        placeholder='Buscar...' 
                    />
                
                <br/>
                {/* elemento de filtro por checkbox */} 
                </div>
                    <fieldset className='checkbox-ramas' id='checkbox-ramas'>
                        <span className='filter-subtitle'>Ramas</span>
                        <ul className='filter-container'>
                            {librosNames(ramasNames,"ramas_checkbox")}
                        </ul>
                        
                    </fieldset>
                    <fieldset className='checkbox-temas' id='checkbox-temas'>
                        <span className='filter-subtitle'>Temas</span>
                        
                        <ul className='filter-container'>
                            {librosNames(temasNames,"temas_checkbox")}
                        </ul>
                    </fieldset>
            </div>
            <div  id='container-libros' className='w3-container'>
                <div className='half-container'>
                    {get_libros}
                </div>
            </div>
        </div>
        </Fragment>
  );
}



export default Biblioteca