import React, {Fragment} from 'react';
import {Link,useParams} from 'react-router-dom';
import Utils from '../generales/utils';



function Rama(props){

    let {rama} = useParams();


    return (
        <Fragment>
            <h1>
                <Link to="/ramas">Rama: </Link>
                {Utils.capitalize(rama)}
            </h1>

            <h2>Descripción general</h2>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate, dolorum mollitia vel debitis ipsam deserunt eaque architecto. Inventore quos id nam quisquam. Labore doloribus, nisi atque quae recusandae esse fuga nihil mollitia quidem enim! Vel, ex odio aspernatur consequatur molestiae quo minima recusandae? Suscipit dolores, in quaerat dolorem error facilis id asperiores non maxime excepturi perspiciatis hic, natus fuga commodi quam? Nobis nesciunt possimus beatae libero sint distinctio delectus officiis eaque voluptas aperiam laudantium molestiae voluptatibus asperiores minima temporibus, optio soluta dignissimos repellat cumque numquam. Illo ut eligendi dolor id. Ab odit repellat rerum quaerat laboriosam consequuntur sequi ipsam omnis?
            </p>            

            <h2>Base de aprendizaje</h2>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate, dolorum mollitia vel debitis ipsam deserunt eaque architecto. Inventore quos id nam quisquam. Labore doloribus, nisi atque quae recusandae esse fuga nihil mollitia quidem enim! Vel, ex odio aspernatur consequatur molestiae quo minima recusandae? Suscipit dolores, in quaerat dolorem error facilis id asperiores non maxime excepturi perspiciatis hic, natus fuga commodi quam? Nobis nesciunt possimus beatae libero sint distinctio delectus officiis eaque voluptas aperiam laudantium molestiae voluptatibus asperiores minima temporibus, optio soluta dignissimos repellat cumque numquam. Illo ut eligendi dolor id. Ab odit repellat rerum quaerat laboriosam consequuntur sequi ipsam omnis?
            </p>  

            <h2>Valores a fomentar</h2>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate, dolorum mollitia vel debitis ipsam deserunt eaque architecto. Inventore quos id nam quisquam. Labore doloribus, nisi atque quae recusandae esse fuga nihil mollitia quidem enim! Vel, ex odio aspernatur consequatur molestiae quo minima recusandae? Suscipit dolores, in quaerat dolorem error facilis id asperiores non maxime excepturi perspiciatis hic, natus fuga commodi quam? Nobis nesciunt possimus beatae libero sint distinctio delectus officiis eaque voluptas aperiam laudantium molestiae voluptatibus asperiores minima temporibus, optio soluta dignissimos repellat cumque numquam. Illo ut eligendi dolor id. Ab odit repellat rerum quaerat laboriosam consequuntur sequi ipsam omnis?
            </p>            
          

            <h2>Bibliografía principal y complementaria</h2>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate, dolorum mollitia vel debitis ipsam deserunt eaque architecto. Inventore quos id nam quisquam. Labore doloribus, nisi atque quae recusandae esse fuga nihil mollitia quidem enim! Vel, ex odio aspernatur consequatur molestiae quo minima recusandae? Suscipit dolores, in quaerat dolorem error facilis id asperiores non maxime excepturi perspiciatis hic, natus fuga commodi quam? Nobis nesciunt possimus beatae libero sint distinctio delectus officiis eaque voluptas aperiam laudantium molestiae voluptatibus asperiores minima temporibus, optio soluta dignissimos repellat cumque numquam. Illo ut eligendi dolor id. Ab odit repellat rerum quaerat laboriosam consequuntur sequi ipsam omnis?
            </p>            


        </Fragment>
    );
}

export default Rama;