import React from 'react';
import InputText from '../base/inputtext';
import PropTypes from 'prop-types';

const Hidden = ( {
        Id="-",
        Valor="",
        ValorSet=()=>console.log("falta agregar la función para asignación en hidden.")
})=>{

    return (
            <InputText 
                Id={Id} 
                Tipo="hidden" 
                Valor={Valor} 
                ValorSet = {ValorSet}
            />
    );
}

Hidden.propTypes = {
    Id : PropTypes.any.isRequired,
    Valor : PropTypes.number.isRequired,
    ValorSet : PropTypes.func.isRequired
}

export default Hidden;