import React, { Fragment } from "react";
import PropTypes from "prop-types";

const InputText = ({
  Tipo = "Text",
  Id = "uno",
  TieneErrores = false,
  PlaceHolder = "Placeholder",
  ErrorDiv ="w3-container",
  ErrorMessage="",
  Valor = "",
  ValorSet = () => {
    console.log("falta definir funcion set de input text");
  },
}) => {
  //   function cambiarValor(nuevoValor) {
  //     //console.log("cambiando valor", nuevoValor);
  //     miValorSet(nuevoValor);
  //     ValorSet(nuevoValor);
  //   }

  // console.log("tiene errores:", TieneErrores);
  return (
    <Fragment>

      <input
        className={`w3-input ${TieneErrores ? "w3-border-red":""}`}
        type={Tipo}
        id={Id}
        name={Id}
        placeholder={PlaceHolder}
        value={Valor}
        onChange={(e) =>{
         ValorSet(e.target.value);
        // console.log("cambiando ", Id, " a ", e.target.value);
        }
        }  
      />
      <div className={`${TieneErrores ? "w3-text-red w3-tiny":"w3-hide"}`}>
          {ErrorMessage}
      </div>
    </Fragment>
  );
};

InputText.propTypes = {
  Tipo: PropTypes.string,
  Id: PropTypes.any,
  TieneErrores: PropTypes.bool,
  ErrorDiv : PropTypes.string,
  ErrorMessage : PropTypes.string,
  PlaceHolder: PropTypes.string,
  Valor: PropTypes.any.isRequired,
  ValorSet: PropTypes.func.isRequired,
};

export default InputText;
