

import { Link } from "react-router-dom";
import LOGO from "../../images/florlis.png";


function Logo(){

    const W = 60;
    return(
        <Link to="/">
            <img src={LOGO} alt="Usca" width={W}/>
        </Link>
    )
}

export default Logo