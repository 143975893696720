
import React, {Fragment, useState, useEffect} from 'react';
import {  Link } from "react-router-dom";
import PropTypes from 'prop-types';


// import { Link } from 'react-router-dom';


const Slides = ({
        slides=[],
        height="250px",
        classtexto="w3-cursive"
    })=>{

    const [currentDiv, currentDivSet] = useState(1);

    useEffect(()=>{
        if(slides.length>0)
            showDiv(currentDiv);

    },[currentDiv,slides]);

    const plusDivs = (num)=>{
        let max=slides.length;
        let min=1;

        if(num>0){
            //Sumo
            if(currentDiv>=max){
                currentDivSet(min);
            } else {
                currentDivSet(currentDiv+1);
            }
        } else {
            if(currentDiv<=min){
                currentDivSet(max);
            } else {
                currentDivSet(currentDiv -1);
            }

        }
        showDiv(currentDiv);
    }

    const showDiv = (current)=>{
        //Recorro todas y las oculto
        for(let i=1;i<=slides.length;i++){
            let id="img"+i;
            let imagen=document.getElementById(id);
            if(i===current){
                //esta le va un "w3-image"
                imagen.className = "w3-container";
            } else {
                //le va un hide
                imagen.className = "w3-hide";
            }
        }
        for(let i=1;i<=slides.length;i++){
            let id="btn"+i;
            let boton=document.getElementById(id);
            if(i===current){
                //esta le va un "w3-image"
                boton.className = "w3-button w3-red";
            } else {
                //le va un hide
                boton.className = "w3-button";
            }
        }

    }





    // Utils.useInterval(plusDivs(1),3000);


        return(
            <Fragment>


            {
                slides.map((item,index)=>{
                    return (

                        <div className='w3-row' key={"imagenes"+index.toString()}>
                            <div className='w3-hide' id={"img"+item.id}>
                                <div className='w3-twothird' style={{overflow: 'hidden'}}>
                                    <Link to={item.link!==""? item.link:"#"}>
                                        <img src={item.imagen} className = "w3-round" 
                                        height={height} 
                                        alt={item.alt} />
                                    </Link>
                                </div>
                                <div className='w3-third w3-left'>
                                    <p className={classtexto}>{item.texto}</p>
                                </div>
                            </div>
                         </div>       

                            
                        );
                })
            }



            <div className="w3-center">
                <div className="w3-section">
                    <button className="w3-button w3-light-grey" onClick={()=>plusDivs(-1)}>❮ Prev</button>
                    <button className="w3-button w3-light-grey" onClick={()=>plusDivs(1)}>Next ❯</button>
                </div>

                {
                    slides.map((item, index)=>{
                        return (
                            <button 
                                key={"botones"+index.toString()}
                                className="w3-button" 
                                id={"btn"+item.id} 
                                onClick={()=>currentDivSet(item.id)}>
                                    {item.id}
                            </button> 
                        );
                    })
                }

                
                {/* <button className="w3-button" id="btn2" onClick={()=>currentDivSet(2)}>2</button> 
                <button className="w3-button" id="btn3" onClick={()=>currentDivSet(3)}>3</button> 
                <button className="w3-button" id="btn4" onClick={()=>currentDivSet(4)}>4</button> 
                <button className="w3-button" id="btn5" onClick={()=>currentDivSet(5)}>5</button>  */}

            </div>


                
            </Fragment>
        )


}

Slides.propTypes = {
    slides: PropTypes.array.isRequired,
    styles: PropTypes.string,
    classtexto: PropTypes.string
}


export default Slides;