import React, { Component } from 'react'
import LOADING from "../../images/cargando.gif";

export default class Loading extends Component {
  render() {
    return (
      <img src={LOADING} alt="Loading" width={50}/>
    )
  }
}




