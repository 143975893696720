
import React from 'react';
import { useParams } from "react-router-dom";

function Dashboard(){
    let {id} = useParams();
    return (
        <div>
            <h1>Dashboard</h1>
            Params:{id}
        </div>
    );
}

export default Dashboard;