import React , {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Select from '../base/select';
import BD from '../context/bd';
import Loading from '../simple/loading';



const Pais = ( {
                Titulo="Pais",
                Id="pais",
                Valor="AR",
                ValorSet=()=>console.log("falta definir la función para actualizar pais")
            } )=>{

    let [isLoading, isLoadingSet]=useState(false);
    let [pais, paisSet]=useState(Valor)
    let [paises, paisesSet]=useState([]);
    let [error, errorSet] = useState(null);

    
    useEffect( () => {
        // console.log("ejecutando useEffect");
        isLoadingSet(true);

        // Si quisiera mandar filtros debería mandar un segundo parámetro a BD.traer
        // con la siguiente información:
        // ,[{"field":"id", "value":["AR", "BR", "CL"]}]

        BD.traer("geo_paises")
        .then((data)=>{
            // console.log(data);
                    paisesSet(data.payload);
                    isLoadingSet(false);
        })
        .catch( (err)=>{
            isLoadingSet(false);
            // console.log("error en BD.traeer en el catch:", JSON.stringify(err));
            errorSet(err);
        });
        
    }, []);
    
    
    // setTimeout(()=>{
    //     paisesSet(data);
    //     // console.log("datos traidos en useEffect", data);
    //     isLoadingSet(false);

    // },5000);


    const actualizarValor = (nuevoValor)=>{
        paisSet(nuevoValor);
        ValorSet(nuevoValor);
    }

    if(error){
        return <div className="w3-panel we-red">
            Error: {error.message}
        </div>;
    } else  {


        return (
            <div className='w3-container'>
                { 
                    isLoading ? 
                    <Loading /> :
                    <Select  
                        Id={Id} 
                        Titulo={Titulo}
                        Clave = "id"
                        Descripcion = "descripcion"
                        Valor={pais}
                        ValorSet={actualizarValor}
                        Valores={paises}
                        Placeholder="Pais"
                    />
                }
            </div>
        );
    }


}

Pais.propTypes = {
    Titulo: PropTypes.string,
    Id: PropTypes.string.isRequired,
    Valor: PropTypes.string,
    ValorSet: PropTypes.func
}

export default Pais;