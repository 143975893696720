import Menu from './menu';

import React , { Fragment } from 'react';

const Header=(props)=>{
    return(
    <Fragment>
      <div className="w3-mobile w3-animate-top">
          <Menu />
      </div>
    </Fragment>

    )
}

export default Header

