import React, {Fragment, useState, useEffect } from 'react';
import PropTypes from "prop-types";

// import {Link} from 'react-router-dom';


const CursosDetalle = ({
    Cursos=[{"nombre":"prueba","objetivo":"Conocer"}]
}) =>{

    let [misCursos, misCursosSet]=useState([]);

    useEffect(()=>{
        console.log("Cursos:", Cursos);
        Cursos.forEach(item=>{
            let encontrado = misCursos.find(curso=>curso.cursoid === item.cursoid);
            if(!encontrado){
                let temp = [...misCursos];
                temp.push(item);
                misCursosSet(temp);
            }
        });


    },[Cursos]);

    return (
            <Fragment>
               {
               misCursos.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            <h3 className='w3-underline'>{item.nombre}</h3>
                            <p><b>Objetivo:</b>{item.objetivos}</p>
                            <p><b>Contenidos:</b>
                            <br />
                            <ul>
                                {
                                    Cursos.map( (unidad,index)=>{
                                        if(item.cursoid===unidad.cursoid)
                                            return <li key={index}>{unidad.unidad}:{unidad.objetivos_uni}</li>
                                        return "";
                                    })
                                }
                            </ul>
                            </p>
                            
                        </Fragment>

                )
              }
            )}
            </Fragment>
    );
}

CursosDetalle.propTypes = {
    Cursos: PropTypes.array,
  };

export default CursosDetalle;