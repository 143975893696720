import React, {Fragment, useState, useEffect } from 'react';
import BD from '../context/bd';
import CursosDetalle from './cursosdetalle';

// import {Link} from 'react-router-dom';


function Cursos(props){
    let [cursosObligatorios, cursosObligatoriosSet] = useState([]);
    let [cursosDirigentes, cursosDirigentesSet] = useState([]);
    

    useEffect(()=>{
        BD.traer(`cur_cursos/all`)
        .then( data => {
            console.log("respuesta:",data);
            if (data.ok){
                //todo bien
                cursosObligatoriosSet(data.payload.filter(item=>item.obligatorio==="1"));
                cursosDirigentesSet(data.payload.filter(item=>item.obligatorio!=="1"));
            }
        })
        .catch ( err => console.log("error: ", err));


    },[]);

    return (
        <Fragment>
            {/* <pre>{cursosObligatorios}</pre>
            <pre>{cursosDirigentes}</pre> */}
            <h1>Cursos</h1>

            <h2 className='w3-border-bottom'>Obligatorios</h2>
            <CursosDetalle Cursos={cursosObligatorios}/>

            <h2 className='w3-border-bottom'>Formación para dirigentes</h2>
            <CursosDetalle Cursos={cursosDirigentes}/>


        </Fragment>
    );
}

export default Cursos;