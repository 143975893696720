import React, {useState} from 'react';
import Label from '../base/label';
import InputText from '../base/inputtext';
import PropTypes from 'prop-types';

const Email = ( {
        Titulo="",
        Id="email",
        TieneErrores = false,
        PlaceHolder="introduzca su eMail", 
        Valor="", 
        ValorSet=()=>console.log("falta agregar la función para asignación en email.")
    } )=>{

    let [email, emailSet] = useState(Valor);

    function actualizarEmail(nuevoValor){
        emailSet(nuevoValor);
        ValorSet(nuevoValor);
    }

    return (
        <div className="w3-container">
            {
                (Titulo!=="" &&
                <Label Texto={Titulo} HtmlFor={Id} />
                )
            }
            <InputText 
                Id={Id} 
                Tipo="email" 
                TieneErrores = {TieneErrores}
                ErrorMessage="Debe completar el email"
                PlaceHolder={PlaceHolder} 
                Valor={email} 
                ValorSet={actualizarEmail}  
            />
        </div>
    );
}

Email.propTypes = {
    Titulo: PropTypes.string.isRequired,
    Id : PropTypes.any.isRequired,
    TieneErrores : PropTypes.bool,
    PlaceHolder : PropTypes.string,
    Valor : PropTypes.string.isRequired,
    ValorSet: PropTypes.func
}

export default Email;